export class ScriptMountUtils {
	public static async setGoogleMapsAPI(lang: string): Promise<unknown> {
		const googleMapsKey = 'AIzaSyBqRbSDy25zVw7RCIKiCbxtE_2pLScFAQE';

		//Generate new Google Maps API script
		const newAPI = document.createElement('script');
		newAPI.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&callback=initMaps&libraries=places&v=weekly&channel=2&language=${lang.toLowerCase()}`;
		newAPI.async = true;
		newAPI.defer = true;

		console.log('MAPS URL:', newAPI.src);

		//Callback for the Google Maps API src
		window['initMaps'] = () => {
			const event = new CustomEvent('initMaps');
			window.dispatchEvent(event);
		};

		//Wait for the callback to be executed
		const apiLoaded = new Promise(resolve => {
			window.addEventListener('initMaps', () => {
				'INIT MAPS RUN';
				resolve('GoogleMapsApi has been loaded');
			});
		});

		//Start the script
		document.head.appendChild(newAPI);

		return apiLoaded;
	}
}
