/* eslint-disable complexity */

import { HttpMethod } from '@app/interfaces/enums/HttpMethod';
import { EGLanguageBCP47 } from '@app/interfaces/uimodels/global/types';
import { DateTimeStampUtils } from '@app/interfaces/utils/DateTimeStampUtils';

export interface AuthData {
	authorization?: string;
	language?: EGLanguageBCP47;
	tryRefreshToken?: () => Promise<{ token: string; refreshToken: string } | undefined>;
	/**
	 * Timeout in milliseconds
	 */
	timeout?: number;
}

export class FetchHelper {
	public static async jsonRequest(authData: AuthData = {}, url = '', body?: any, httpMethod?: HttpMethod): Promise<Response | undefined> {
		const bodyIsJsonObject = body == undefined || typeof body === 'object';

		const controller = new AbortController();
		const abortTimeoutId = setTimeout(() => controller.abort(), authData?.timeout ?? 600_000);

		const options: RequestInit = {
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {},
			signal: controller.signal
		};

		if (bodyIsJsonObject) {
			options.headers = {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			};
		}

		if (authData && authData.authorization && authData.authorization !== 'cookie') {
			(options.headers!)['Authorization'] = authData.authorization;
		}

		(options.headers!)['Accept-Language'] = authData?.language ?? EGLanguageBCP47.DE;
		(options.headers!)['Preferred-Language'] = authData?.language ?? EGLanguageBCP47.DE;
		(options.headers!)['Current-Timezone'] = DateTimeStampUtils.getCurrentTimezone(true);

		if (body) {
			options.method = httpMethod ?? 'POST';
			options.body = bodyIsJsonObject ? JSON.stringify(body) : body;
		} else {
			options.method = httpMethod ?? 'GET';
		}

		try {
			const result = await fetch(url, options);

			if (result.status == 401 && authData && authData.tryRefreshToken) {
				const refreshResult = await authData.tryRefreshToken();

				if (refreshResult) {
					// retry request, no more refresh retries after this one
					return await FetchHelper.jsonRequest(
						{ authorization: refreshResult.token },
						url,
						body
					);
				} else {
					return result;
				}
			}

			return result;
		} catch (error) {
			console.error(`Cannot execute request ${options.method} ${url}: ${(error as Record<string, string>)?.message ?? 'unknown error'}`);
		} finally {
			clearTimeout(abortTimeoutId);
		}

		return undefined;
	}
}
