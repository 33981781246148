import {
	enableProdMode,
	isDevMode
} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FrontendConstants } from '@app/interfaces/constants/frontend.constants';
import { EnvironmentConfigKeyName } from '@app/interfaces/server/environment.interface';
import { EGLanguage } from '@app/interfaces/uimodels/global/types';
import { FetchHelper } from '@app/services/remoteapi/fetchhelper';
import { CookiesUtils } from '@app/utils/CookiesUtils';
import { ScriptMountUtils } from '@app/utils/ScriptMountUtils';
import { environment } from '@environments/environment';

const setupPlatform = async(): Promise<void> => {
	//Deferred import: otherwise is AppModule imported instantly and as it is using decorator will be bootstrapped before main.ts is run and platformSetup is done
	import('@app/app.module').then((AppModule) => {
		platformBrowserDynamic()
			.bootstrapModule(AppModule.AppModule)
			.catch((err) => console.error(err));
	});
};

const setEnvironment = (): void => {
	const proxyMap: Promise<Record<string, unknown>> = window['pspa_proxymap'] ?? {};
	const proxyMapEnvironmentObj = proxyMap[EnvironmentConfigKeyName];

	if (proxyMapEnvironmentObj) {
		Object.keys(proxyMapEnvironmentObj)
			.forEach((key) => {
				environment[key] = proxyMapEnvironmentObj[key];
			});
	} else {
		console.warn('Environment config keys not set');
	}
};

const setProxyMap = async(): Promise<void> => {
	// eslint-disable-next-line camelcase
	if (!(window)['pspa_proxymap']) {
		const result = await FetchHelper.jsonRequest({ authorization: undefined }, '/api/transformer/proxymap');

		if (result?.status === 200) {
			const body = await result.text();
			const proxyMap = JSON.parse(body);
			// eslint-disable-next-line camelcase
			(window)['pspa_proxymap'] = proxyMap;
		}
	}
};

async function setupLocale(): Promise<string> {
	let cookieLocale = await CookiesUtils.getCookie(FrontendConstants.preferredLocaleCookieField);
	const userBrowserLanguage = navigator
		.language.split('-')
		.shift()
		?.toLocaleLowerCase() ?? 'en';

	if (!cookieLocale) {
		const browserLanguageSupported = Boolean(EGLanguage[userBrowserLanguage]);

		if (browserLanguageSupported) {
			CookiesUtils.setCookie(FrontendConstants.preferredLocaleCookieField, EGLanguage[userBrowserLanguage]);
		} else {
			CookiesUtils.setCookie(FrontendConstants.preferredLocaleCookieField, EGLanguage.en);
		}

		cookieLocale = await CookiesUtils.getCookie(FrontendConstants.preferredLocaleCookieField);
	}

	return cookieLocale;
}

(async() => {
	const locale = await setupLocale();

	const googleMapsApiLoaded = await ScriptMountUtils.setGoogleMapsAPI(locale);
	console.debug(googleMapsApiLoaded);

	await setProxyMap();
	setEnvironment();

	if (environment.production) {
		enableProdMode();
	}
	console.debug('ENVIRONMENT: ', environment?.envName);
	console.debug('DEV MODE: ', isDevMode());

	setupPlatform();
})();
